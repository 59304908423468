/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const SourceSansPro_200ExtraLight = require('./SourceSansPro_200ExtraLight.ttf');
export const SourceSansPro_200ExtraLight_Italic = require('./SourceSansPro_200ExtraLight_Italic.ttf');
export const SourceSansPro_300Light = require('./SourceSansPro_300Light.ttf');
export const SourceSansPro_300Light_Italic = require('./SourceSansPro_300Light_Italic.ttf');
export const SourceSansPro_400Regular = require('./SourceSansPro_400Regular.ttf');
export const SourceSansPro_400Regular_Italic = require('./SourceSansPro_400Regular_Italic.ttf');
export const SourceSansPro_600SemiBold = require('./SourceSansPro_600SemiBold.ttf');
export const SourceSansPro_600SemiBold_Italic = require('./SourceSansPro_600SemiBold_Italic.ttf');
export const SourceSansPro_700Bold = require('./SourceSansPro_700Bold.ttf');
export const SourceSansPro_700Bold_Italic = require('./SourceSansPro_700Bold_Italic.ttf');
export const SourceSansPro_900Black = require('./SourceSansPro_900Black.ttf');
export const SourceSansPro_900Black_Italic = require('./SourceSansPro_900Black_Italic.ttf');
